<template>

  <div id="about-me">

    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>

    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>

    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>

    <v-row align="center">
      <v-col>
        <v-card elevation="15" tile min-height="350">
          <div class="d-none d-sm-flex">
            <v-container class="pa-10">
              <v-row align="center">
                <v-col cols="12" lg="6" class="pa-10" align="center">
                  <v-img :aspect-ratio="9/16"
                         :width="450"
                         :src="about_me_image"
                         class="align-center grey">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col cols="12" lg="6" class="pa-10 align-self-center text-justify">
                  Dzień dobry, nazywam się Marcin Piasecki. Moje doświadczenia zawodowe
                  sięgają roku 2003, kiedy rozpocząłem pracę w branży finansowej,
                  zdobywając umiejętności związane z działalnością ubezpieczeniową w
                  firmie Amplico AIG LIFE. Tam nabyłem umiejętności z zakresu
                  profesjonalnej działalności ubezpieczeniowej. W późniejszym okresie
                  kontynuowałem je w firmie Allianz, gdzie uczestniczyłem w wielu
                  projektach mających na celu rozwój produktów i współpracę z klientami, za
                  co wielokrotnie otrzymywałem nagrody w oddziale, w którym
                  rozpoczynałem pracę.
                  <br/> <br/>
                  W roku 2009 nawiązałem współpracę z firmą AXA Polska jako Główny
                  Negocjator Ubezpieczeń Grupowych. Zajmowałem się projektowaniem,
                  przygotowywaniem i wdrażaniem programów z zakresu ubezpieczeń
                  grupowych czy opieki medycznej. Czas spędzony w AXA pozwolił mi poznać
                  charakter i zasady programów korporacyjnych i międzynarodowych
                  poprzez budowanie i rozwijanie współpracy z wieloma kluczowymi
                  klientami.
                  <br/> <br/>
                  W 2015 roku podjąłem decyzję o założeniu własnej firmy zajmującej się
                  sprzedażą benefitów, dopasowującej programy ubezpieczeń grupowych
                  różnych sektorów czy indywidualnie zaprojektowanych programów
                  ubezpieczeń grupowych do oferty czołowych firm ubezpieczeniowych
                  działających na rynku polskim i przekazującej korzyści nowatorskich
                  programów klientom poprzez współpracę z czołowymi firmami z zakresu
                  ubezpieczeń życiowych i z partnerami medycznymi.
                  <br/> <br/>
                  Rok 2018 potwierdził, iż decyzja była słuszna, poznałem sektor
                  ubezpieczeń z drugiej strony – jako ich beneficjent. Doznałem wypadku,
                  <br/> <br/>
                  otarłem się o wózek inwalidzki. Zdarzenie to wyeliminowało mnie na
                  prawie trzy lata z działalności zawodowej. Czas ten musiałem przeznaczyć
                  na rehabilitację. Z konsekwencjami wypadku będę się mierzył do końca
                  życia. Mierzę się z nimi każdego dnia.
                  <br/> <br/>
                  Z tej perspektywy mogę wspierać swoich Klientów nie tylko radą,
                  osobistymi doświadczeniami, lecz i znajomością rynku ubezpieczeniowego.
                  Poznałem tę branżę w sposób wyjątkowy.
                  <br/> <br/>
                  Dlatego powstała Firma Piasecki i Partnerzy.
                </v-col>

              </v-row>
            </v-container>
          </div>
          <div class="d-flex d-sm-none">
            <v-container>
              <v-row align="center">
                <v-col cols="12" lg="6" class="pa-10" align="center">
                  <v-img :aspect-ratio="9/16"
                         :width="450"
                         :src="about_me_image"
                         class="align-center grey">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col cols="12" lg="6" class="pa-10 align-self-center text-justify">
                  Dzień dobry, nazywam się Marcin Piasecki. Moje doświadczenia zawodowe
                  sięgają roku 2003, kiedy rozpocząłem pracę w branży finansowej,
                  zdobywając umiejętności związane z działalnością ubezpieczeniową w
                  firmie Amplico AIG LIFE. Tam nabyłem umiejętności z zakresu
                  profesjonalnej działalności ubezpieczeniowej. W późniejszym okresie
                  kontynuowałem je w firmie Allianz, gdzie uczestniczyłem w wielu
                  projektach mających na celu rozwój produktów i współpracę z klientami, za
                  co wielokrotnie otrzymywałem nagrody w oddziale, w którym
                  rozpoczynałem pracę.
                  <br/> <br/>
                  W roku 2009 nawiązałem współpracę z firmą AXA Polska jako Główny
                  Negocjator Ubezpieczeń Grupowych. Zajmowałem się projektowaniem,
                  przygotowywaniem i wdrażaniem programów z zakresu ubezpieczeń
                  grupowych czy opieki medycznej. Czas spędzony w AXA pozwolił mi poznać
                  charakter i zasady programów korporacyjnych i międzynarodowych
                  poprzez budowanie i rozwijanie współpracy z wieloma kluczowymi
                  klientami.
                  <br/> <br/>
                  W 2015 roku podjąłem decyzję o założeniu własnej firmy zajmującej się
                  sprzedażą benefitów, dopasowującej programy ubezpieczeń grupowych
                  różnych sektorów czy indywidualnie zaprojektowanych programów
                  ubezpieczeń grupowych do oferty czołowych firm ubezpieczeniowych
                  działających na rynku polskim i przekazującej korzyści nowatorskich
                  programów klientom poprzez współpracę z czołowymi firmami z zakresu
                  ubezpieczeń życiowych i z partnerami medycznymi.
                  <br/> <br/>
                  Rok 2018 potwierdził, iż decyzja była słuszna, poznałem sektor
                  ubezpieczeń z drugiej strony – jako ich beneficjent. Doznałem wypadku,
                  <br/> <br/>
                  otarłem się o wózek inwalidzki. Zdarzenie to wyeliminowało mnie na
                  prawie trzy lata z działalności zawodowej. Czas ten musiałem przeznaczyć
                  na rehabilitację. Z konsekwencjami wypadku będę się mierzył do końca
                  życia. Mierzę się z nimi każdego dnia.
                  <br/> <br/>
                  Z tej perspektywy mogę wspierać swoich Klientów nie tylko radą,
                  osobistymi doświadczeniami, lecz i znajomością rynku ubezpieczeniowego.
                  Poznałem tę branżę w sposób wyjątkowy.
                  <br/> <br/>
                  Dlatego powstała Firma Piasecki i Partnerzy.
                </v-col>

              </v-row>
            </v-container>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-10"></v-col>
    </v-row>

  </div>

</template>

<script>
export default {
  name: "AboutMe",
  data() {
    return {
      about_me_image: require('../assets/img/23122.jpg'),
    }
  },
}
</script>

<style scoped>

</style>